<template>
   <profile-header v-if="AUTH" />
   <header-main :open_tarif="open_tarif" v-else></header-main>
   <main class="main">
      <section class="hero">
         <div class="container">
            <div class="hero__card">
               <h1 class="hero__title title">
                  Предоставляем самую подробную аналитику тендеров в России
               </h1>
               <div class="hero__buttons">
                  <a
                     @click="GoToRef('contactform')"
                     class="hero__btn hero__btn_white btn"
                     >Попробовать бесплатно</a
                  >
                  <a
                     @click="GoToRef('preferences')"
                     class="hero__btn hero__btn_black btn"
                     >Узнать больше</a
                  >
               </div>
            </div>
         </div>
      </section>
      <section style="transform: translateY(-80px)" ref="preferences"></section>
      <section class="preferences" data-aos="fade-up" data-aos-duration="1000">
         <div class="container">
            <div class="preferences__grid">
               <div
                  class="preferences__item preferences__item_upper preferences__data"
               >
                  Большая база <br />
                  источников данных
               </div>
               <div
                  class="preferences__item preferences__item_upper preferences__filters"
               >
                  Гибкая настройка <br />
                  фильтров
               </div>
               <div
                  class="preferences__item preferences__item_upper preferences__reports"
               >
                  Подробная аналитика <br />
                  и выгрузка отчетов
               </div>
               <div
                  class="preferences__item preferences__item_down preferences__stats"
               >
                  <p>
                     <span class="title">+50%</span> Больше выдача, <br />
                     чем у конкурентов
                  </p>
                  <p>
                     <span class="title">> 130 млн</span> текущих и завершенных
                     закупок <br />
                     по состоянию на сегодня
                  </p>
               </div>
               <div
                  class="preferences__item preferences__item_down preferences__info"
               >
                  <p class="title">
                     Мы собираем информацию и предоставляем ее в удобном виде
                  </p>
                  <p>
                     PROTENDER - один из крупнейших в России агрегаторов
                     госзакупок, тендеров и торгов госкорпораций и крупных
                     коммерческих компаний с эффективными инструментами
                     аналитики и автоматизации. Удобные поисковые алгоритмы
                     обрабатывают информацию с большинства действующих
                     электронных магазинов, торговых площадок.
                  </p>
               </div>
            </div>
         </div>
      </section>
      <section style="transform: translateY(-80px)" ref="oportunity"></section>
      <section class="benefit" data-aos="fade-up" data-aos-duration="1000">
         <div class="container">
            <div class="benefit__card">
               <div class="benefit__title title">
                  Мы стремимся, чтобы наши клиенты получали максимальную выгоду
               </div>
               <div class="benefit__search benefit-search">
                  <div class="benefit-search__text benefit__text">
                     <div class="benefit-search__title benefit__subtitle">
                        Поиск закупок
                     </div>
                     <div class="benefit-search__desc benefit__desc">
                        Вы можете быстро и удобно найти информацию о различных
                        тендерах, аукционах и других формах государственных и
                        коммерческих закупок.
                     </div>
                     <a
                        @click="GoToRef('subs')"
                        class="benefit-search__button benefit__button btn"
                        >Выбрать тариф</a
                     >
                  </div>
                  <div class="benefit-search__main benefit__main">
                     <div class="benefit-main__wrapper">
                        <div class="benefit-search__input">
                           <input
                              disabled
                              type="text"
                              placeholder="Поиск по закупкам"
                              id="benefit-search__input"
                           />
                           <label or="benefit-search__input">
                              <button></button>
                           </label>
                        </div>
                        <button class="benefit-search__filters"></button>
                        <div class="benefit-search__card card-search">
                           <div class="card-search__row">
                              <span>ТЭК Торг</span>
                              <span>44-ФЗ</span>
                              <span>АУК</span>
                           </div>
                           <div class="card-search__title">
                              Поставка регулирующих приборов
                           </div>
                           <div class="card-search__subtitle">
                              Офисное оборудование
                           </div>
                           <div class="card-search__price">
                              <span class="price">35 569 275 ₽</span>
                              <div class="card-search__more">
                                 <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                 >
                                    <circle
                                       cx="5.5"
                                       cy="10.5"
                                       r="1.5"
                                       fill="#65656A"
                                    />
                                    <circle
                                       cx="10.5"
                                       cy="10.5"
                                       r="1.5"
                                       fill="#65656A"
                                    />
                                    <circle
                                       cx="15.5"
                                       cy="10.5"
                                       r="1.5"
                                       fill="#65656A"
                                    />
                                 </svg>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="benefit__analytics benefit-analytics">
                  <div class="benefit__text benefit-analytics__text">
                     <div class="benefit-analytics__title benefit__subtitle">
                        Аналитика контрагентов
                     </div>
                     <div class="benefit-analytics__desc benefit__desc">
                        Наша платформа позволяет проверять контрагентов налету.
                        Вам не придется использовать сторонние сервисы.
                     </div>
                     <a
                        @click="GoToRef('subs')"
                        class="benefit-analytics__button benefit__button btn"
                        >Выбрать тариф</a
                     >
                  </div>
                  <div class="benefit__main benefit-analytics__main">
                     <div class="benefit-analytics__wrapper">
                        <div class="benefit-analytics__stats">
                           <span>Завершенные закупки: 12 </span>
                           <span>Текущие закупки: 7</span>
                        </div>
                        <div class="benefit-analytics__graphs benefit-graphs">
                           <div
                              class="benefit-graphs__item benefit-graphs__item_2016"
                           >
                              <div class="benefit-graphs__lines">
                                 <div
                                    class="benefit-graphs__line benefit-graphs__line_plans"
                                 ></div>
                                 <div
                                    class="benefit-graphs__line benefit-graphs__line_purch"
                                 ></div>
                                 <div
                                    class="benefit-graphs__line benefit-graphs__line_contracts"
                                 ></div>
                              </div>
                              <div class="benefit-graphs__year">2016</div>
                           </div>
                           <div
                              class="benefit-graphs__item benefit-graphs__item_2017"
                           >
                              <div class="benefit-graphs__lines">
                                 <div
                                    class="benefit-graphs__line benefit-graphs__line_plans"
                                 ></div>
                                 <div
                                    class="benefit-graphs__line benefit-graphs__line_purch"
                                 ></div>
                                 <div
                                    class="benefit-graphs__line benefit-graphs__line_contracts"
                                 ></div>
                              </div>
                              <div class="benefit-graphs__year">2017</div>
                           </div>
                           <div
                              class="benefit-graphs__item benefit-graphs__item_2018"
                           >
                              <div class="benefit-graphs__lines">
                                 <div
                                    class="benefit-graphs__line benefit-graphs__line_plans"
                                 ></div>
                                 <div
                                    class="benefit-graphs__line benefit-graphs__line_purch"
                                 ></div>
                                 <div
                                    class="benefit-graphs__line benefit-graphs__line_contracts"
                                 ></div>
                              </div>
                              <div class="benefit-graphs__year">2018</div>
                           </div>
                           <div
                              class="benefit-graphs__item benefit-graphs__item_2019"
                           >
                              <div class="benefit-graphs__lines">
                                 <div
                                    class="benefit-graphs__line benefit-graphs__line_plans"
                                 ></div>
                                 <div
                                    class="benefit-graphs__line benefit-graphs__line_purch"
                                 ></div>
                                 <div
                                    class="benefit-graphs__line benefit-graphs__line_contracts"
                                 ></div>
                              </div>
                              <div class="benefit-graphs__year">2019</div>
                           </div>
                           <div
                              class="benefit-graphs__item benefit-graphs__item_2020"
                           >
                              <div class="benefit-graphs__lines">
                                 <div
                                    class="benefit-graphs__line benefit-graphs__line_plans"
                                 ></div>
                                 <div
                                    class="benefit-graphs__line benefit-graphs__line_purch"
                                 ></div>
                                 <div
                                    class="benefit-graphs__line benefit-graphs__line_contracts"
                                 ></div>
                              </div>
                              <div class="benefit-graphs__year">2020</div>
                           </div>
                        </div>
                        <div class="benefit-analytics__symbols">
                           <span class="plans">Планы</span>
                           <span class="purchases">Закупки</span>
                           <span class="contracts">Договоры</span>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="benefit__tools benefit-tools">
                  <div class="benefit-tools__wrapper">
                     <div class="benefit-tools__title benefit__subtitle">
                        Удобные инструменты
                     </div>
                     <ul class="benefit-tools__list">
                        <li>
                           <a
                              @click="BenefitTabs('benefitTemplates')"
                              class="benefit-tools__link"
                              :class="{ active: benefit == 'benefitTemplates' }"
                           >
                              <svg
                                 width="16"
                                 height="16"
                                 viewBox="0 0 16 16"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg"
                              >
                                 <path
                                    d="M10.5873 7.00008L11.6673 8.08008V10.3334H9.66732V7.00008H10.5873ZM14.6673 5.33341V12.0001C14.6673 12.7334 14.0673 13.3334 13.334 13.3334H2.66732C1.93398 13.3334 1.33398 12.7334 1.33398 12.0001L1.34065 4.00008C1.34065 3.26675 1.93398 2.66675 2.66732 2.66675H6.66732L8.00065 4.00008H13.334C14.0673 4.00008 14.6673 4.60008 14.6673 5.33341ZM12.6673 7.66675L11.0007 6.00008H8.66732V11.3334H12.6673V7.66675Z"
                                    fill="#1E2024"
                                 />
                                 <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M12.6673 7.66675L11.0007 6.00008H8.66732V11.3334H12.6673V7.66675ZM11.6673 8.08008L10.5873 7.00008H9.66732V10.3334H11.6673V8.08008Z"
                                    fill="#1E2024"
                                 />
                              </svg>
                              Шаблоны</a
                           >
                        </li>
                        <li>
                           <a
                              @click="BenefitTabs('benefitNotifications')"
                              class="benefit-tools__link"
                              :class="{
                                 active: benefit == 'benefitNotifications',
                              }"
                           >
                              <svg
                                 width="16"
                                 height="16"
                                 viewBox="0 0 16 16"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg"
                              >
                                 <path
                                    d="M6.66667 13.9999H9.33333C9.33333 14.7333 8.73333 15.3333 8 15.3333C7.26667 15.3333 6.66667 14.7333 6.66667 13.9999ZM14 12.6666V13.3333H2V12.6666L3.33333 11.3333V7.33325C3.33333 5.26659 4.66667 3.46659 6.66667 2.86659V2.66659C6.66667 1.93325 7.26667 1.33325 8 1.33325C8.73333 1.33325 9.33333 1.93325 9.33333 2.66659V2.86659C11.3333 3.46659 12.6667 5.26659 12.6667 7.33325V11.3333L14 12.6666ZM11.3333 7.33325C11.3333 5.46659 9.86667 3.99992 8 3.99992C6.13333 3.99992 4.66667 5.46659 4.66667 7.33325V11.9999H11.3333V7.33325Z"
                                    fill="white"
                                 />
                                 <path
                                    d="M11.3333 7.33325C11.3333 5.46659 9.86667 3.99992 8 3.99992C6.13333 3.99992 4.66667 5.46659 4.66667 7.33325V11.9999H11.3333V7.33325Z"
                                    fill="white"
                                 />
                                 <path
                                    d="M2 6.66675C2 4.66675 2.66667 3.66675 4 2.66675"
                                    stroke="white"
                                    stroke-width="2"
                                 />
                                 <path
                                    d="M14 6.66675C14 4.66675 13.3333 3.66675 12 2.66675"
                                    stroke="white"
                                    stroke-width="2"
                                 />
                              </svg>
                              Уведомления</a
                           >
                        </li>
                        <li>
                           <a
                              @click="BenefitTabs('benefitTags')"
                              class="benefit-tools__link"
                              :class="{ active: benefit == 'benefitTags' }"
                           >
                              <svg
                                 width="16"
                                 height="16"
                                 viewBox="0 0 16 16"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg"
                              >
                                 <path
                                    d="M14.8312 8.555L12.165 12.555C12.0737 12.6919 11.9499 12.8041 11.8048 12.8818C11.6597 12.9594 11.4977 13 11.3331 13H1.49999C1.40877 13 1.31929 12.975 1.24124 12.9278C1.16318 12.8806 1.09953 12.813 1.05717 12.7322C1.01482 12.6514 0.995367 12.5605 1.00093 12.4695C1.0065 12.3784 1.03686 12.2907 1.08874 12.2156L3.99999 8L1.08874 3.78437C1.03686 3.70935 1.0065 3.62155 1.00093 3.5305C0.995367 3.43945 1.01482 3.34861 1.05717 3.26783C1.09953 3.18704 1.16318 3.11937 1.24124 3.07217C1.31929 3.02496 1.40877 3 1.49999 3H11.3331C11.4977 3 11.6597 3.04061 11.8048 3.11823C11.9499 3.19586 12.0737 3.3081 12.165 3.445L14.8312 7.445C14.9409 7.60933 14.9994 7.80245 14.9994 8C14.9994 8.19755 14.9409 8.39067 14.8312 8.555Z"
                                    fill="white"
                                 />
                              </svg>
                              Метки</a
                           >
                        </li>
                     </ul>
                     <div class="benefit-tools__desc benefit__desc">
                        Сохраняйте необходимые параметры поиска и получайте
                        уведомления о появлении новых закупок по заданным
                        параметрам
                     </div>
                     <a
                        @click="GoToRef('subs')"
                        class="benefit-tools__button benefit__button btn"
                        >Выбрать тариф</a
                     >
                  </div>
                  <div class="benefit-tools__content">
                     <div
                        id="benefitTemplates"
                        class="benefit-tools__templates benefit-templates benefit-tools__tab"
                        :class="{ active: benefit == 'benefitTemplates' }"
                     >
                        <div class="benefit-templates__wrapper">
                           <ul class="benefit-templates__list">
                              <li class="benefit-templates__item">
                                 <div class="folder">
                                    <svg
                                       width="16"
                                       height="16"
                                       viewBox="0 0 16 16"
                                       fill="none"
                                       xmlns="http://www.w3.org/2000/svg"
                                    >
                                       <path
                                          d="M10.5873 7.00008L11.6673 8.08008V10.3334H9.66732V7.00008H10.5873ZM14.6673 5.33341V12.0001C14.6673 12.7334 14.0673 13.3334 13.334 13.3334H2.66732C1.93398 13.3334 1.33398 12.7334 1.33398 12.0001L1.34065 4.00008C1.34065 3.26675 1.93398 2.66675 2.66732 2.66675H6.66732L8.00065 4.00008H13.334C14.0673 4.00008 14.6673 4.60008 14.6673 5.33341ZM12.6673 7.66675L11.0007 6.00008H8.66732V11.3334H12.6673V7.66675Z"
                                          fill="#797B7E"
                                       />
                                       <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M12.6673 7.66675L11.0007 6.00008H8.66732V11.3334H12.6673V7.66675ZM11.6673 8.08008L10.5873 7.00008H9.66732V10.3334H11.6673V8.08008Z"
                                          fill="#797B7E"
                                       />
                                    </svg>
                                 </div>
                                 <span
                                    >Этап Приём заявок, с нач. ценой от 500 000
                                    ₽</span
                                 >
                                 <div class="more">
                                    <svg
                                       width="20"
                                       height="20"
                                       viewBox="0 0 20 20"
                                       fill="none"
                                       xmlns="http://www.w3.org/2000/svg"
                                    >
                                       <circle
                                          cx="5.5"
                                          cy="10.5"
                                          r="1.5"
                                          fill="#65656A"
                                       />
                                       <circle
                                          cx="10.5"
                                          cy="10.5"
                                          r="1.5"
                                          fill="#65656A"
                                       />
                                       <circle
                                          cx="15.5"
                                          cy="10.5"
                                          r="1.5"
                                          fill="#65656A"
                                       />
                                    </svg>
                                 </div>
                              </li>
                              <li class="benefit-templates__item">
                                 <div class="folder">
                                    <svg
                                       width="16"
                                       height="16"
                                       viewBox="0 0 16 16"
                                       fill="none"
                                       xmlns="http://www.w3.org/2000/svg"
                                    >
                                       <path
                                          d="M10.5873 7.00008L11.6673 8.08008V10.3334H9.66732V7.00008H10.5873ZM14.6673 5.33341V12.0001C14.6673 12.7334 14.0673 13.3334 13.334 13.3334H2.66732C1.93398 13.3334 1.33398 12.7334 1.33398 12.0001L1.34065 4.00008C1.34065 3.26675 1.93398 2.66675 2.66732 2.66675H6.66732L8.00065 4.00008H13.334C14.0673 4.00008 14.6673 4.60008 14.6673 5.33341ZM12.6673 7.66675L11.0007 6.00008H8.66732V11.3334H12.6673V7.66675Z"
                                          fill="#797B7E"
                                       />
                                       <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M12.6673 7.66675L11.0007 6.00008H8.66732V11.3334H12.6673V7.66675ZM11.6673 8.08008L10.5873 7.00008H9.66732V10.3334H11.6673V8.08008Z"
                                          fill="#797B7E"
                                       />
                                    </svg>
                                 </div>
                                 <span>Системы безопасности</span>
                                 <div class="more">
                                    <svg
                                       width="20"
                                       height="20"
                                       viewBox="0 0 20 20"
                                       fill="none"
                                       xmlns="http://www.w3.org/2000/svg"
                                    >
                                       <circle
                                          cx="5.5"
                                          cy="10.5"
                                          r="1.5"
                                          fill="#65656A"
                                       />
                                       <circle
                                          cx="10.5"
                                          cy="10.5"
                                          r="1.5"
                                          fill="#65656A"
                                       />
                                       <circle
                                          cx="15.5"
                                          cy="10.5"
                                          r="1.5"
                                          fill="#65656A"
                                       />
                                    </svg>
                                 </div>
                              </li>
                              <li class="benefit-templates__item">
                                 <div class="folder">
                                    <svg
                                       width="16"
                                       height="16"
                                       viewBox="0 0 16 16"
                                       fill="none"
                                       xmlns="http://www.w3.org/2000/svg"
                                    >
                                       <path
                                          d="M10.5873 7.00008L11.6673 8.08008V10.3334H9.66732V7.00008H10.5873ZM14.6673 5.33341V12.0001C14.6673 12.7334 14.0673 13.3334 13.334 13.3334H2.66732C1.93398 13.3334 1.33398 12.7334 1.33398 12.0001L1.34065 4.00008C1.34065 3.26675 1.93398 2.66675 2.66732 2.66675H6.66732L8.00065 4.00008H13.334C14.0673 4.00008 14.6673 4.60008 14.6673 5.33341ZM12.6673 7.66675L11.0007 6.00008H8.66732V11.3334H12.6673V7.66675Z"
                                          fill="#797B7E"
                                       />
                                       <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M12.6673 7.66675L11.0007 6.00008H8.66732V11.3334H12.6673V7.66675ZM11.6673 8.08008L10.5873 7.00008H9.66732V10.3334H11.6673V8.08008Z"
                                          fill="#797B7E"
                                       />
                                    </svg>
                                 </div>
                                 <span>Проектирование систем безопасности</span>
                                 <div class="more">
                                    <svg
                                       width="20"
                                       height="20"
                                       viewBox="0 0 20 20"
                                       fill="none"
                                       xmlns="http://www.w3.org/2000/svg"
                                    >
                                       <circle
                                          cx="5.5"
                                          cy="10.5"
                                          r="1.5"
                                          fill="#65656A"
                                       />
                                       <circle
                                          cx="10.5"
                                          cy="10.5"
                                          r="1.5"
                                          fill="#65656A"
                                       />
                                       <circle
                                          cx="15.5"
                                          cy="10.5"
                                          r="1.5"
                                          fill="#65656A"
                                       />
                                    </svg>
                                 </div>
                              </li>
                              <li class="benefit-templates__item">
                                 <div class="folder">
                                    <svg
                                       width="16"
                                       height="16"
                                       viewBox="0 0 16 16"
                                       fill="none"
                                       xmlns="http://www.w3.org/2000/svg"
                                    >
                                       <path
                                          d="M10.5873 7.00008L11.6673 8.08008V10.3334H9.66732V7.00008H10.5873ZM14.6673 5.33341V12.0001C14.6673 12.7334 14.0673 13.3334 13.334 13.3334H2.66732C1.93398 13.3334 1.33398 12.7334 1.33398 12.0001L1.34065 4.00008C1.34065 3.26675 1.93398 2.66675 2.66732 2.66675H6.66732L8.00065 4.00008H13.334C14.0673 4.00008 14.6673 4.60008 14.6673 5.33341ZM12.6673 7.66675L11.0007 6.00008H8.66732V11.3334H12.6673V7.66675Z"
                                          fill="#797B7E"
                                       />
                                       <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M12.6673 7.66675L11.0007 6.00008H8.66732V11.3334H12.6673V7.66675ZM11.6673 8.08008L10.5873 7.00008H9.66732V10.3334H11.6673V8.08008Z"
                                          fill="#797B7E"
                                       />
                                    </svg>
                                 </div>
                                 <span>Системы наружного видеонаблюдения</span>
                                 <div class="more">
                                    <svg
                                       width="20"
                                       height="20"
                                       viewBox="0 0 20 20"
                                       fill="none"
                                       xmlns="http://www.w3.org/2000/svg"
                                    >
                                       <circle
                                          cx="5.5"
                                          cy="10.5"
                                          r="1.5"
                                          fill="#65656A"
                                       />
                                       <circle
                                          cx="10.5"
                                          cy="10.5"
                                          r="1.5"
                                          fill="#65656A"
                                       />
                                       <circle
                                          cx="15.5"
                                          cy="10.5"
                                          r="1.5"
                                          fill="#65656A"
                                       />
                                    </svg>
                                 </div>
                              </li>
                              <li class="benefit-templates__item">
                                 <div class="folder">
                                    <svg
                                       width="16"
                                       height="16"
                                       viewBox="0 0 16 16"
                                       fill="none"
                                       xmlns="http://www.w3.org/2000/svg"
                                    >
                                       <path
                                          d="M10.5873 7.00008L11.6673 8.08008V10.3334H9.66732V7.00008H10.5873ZM14.6673 5.33341V12.0001C14.6673 12.7334 14.0673 13.3334 13.334 13.3334H2.66732C1.93398 13.3334 1.33398 12.7334 1.33398 12.0001L1.34065 4.00008C1.34065 3.26675 1.93398 2.66675 2.66732 2.66675H6.66732L8.00065 4.00008H13.334C14.0673 4.00008 14.6673 4.60008 14.6673 5.33341ZM12.6673 7.66675L11.0007 6.00008H8.66732V11.3334H12.6673V7.66675Z"
                                          fill="#797B7E"
                                       />
                                       <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M12.6673 7.66675L11.0007 6.00008H8.66732V11.3334H12.6673V7.66675ZM11.6673 8.08008L10.5873 7.00008H9.66732V10.3334H11.6673V8.08008Z"
                                          fill="#797B7E"
                                       />
                                    </svg>
                                 </div>
                                 <span>Сиогнализации</span>
                                 <div class="more">
                                    <svg
                                       width="20"
                                       height="20"
                                       viewBox="0 0 20 20"
                                       fill="none"
                                       xmlns="http://www.w3.org/2000/svg"
                                    >
                                       <circle
                                          cx="5.5"
                                          cy="10.5"
                                          r="1.5"
                                          fill="#65656A"
                                       />
                                       <circle
                                          cx="10.5"
                                          cy="10.5"
                                          r="1.5"
                                          fill="#65656A"
                                       />
                                       <circle
                                          cx="15.5"
                                          cy="10.5"
                                          r="1.5"
                                          fill="#65656A"
                                       />
                                    </svg>
                                 </div>
                              </li>
                           </ul>
                           <div class="benefit-templates__articles">
                              <div class="benefit-templates__article">
                                 Ищем тендеры заказчика МО РФ по предметам
                                 системы видеонаблюдения с любым типом закупки,
                                 с местом поставки г. Москва
                              </div>
                              <div class="benefit-templates__resultat">
                                 Найденные тендеры
                              </div>
                              <ul class="benefit-templates__tenders">
                                 <li
                                    class="benefit-templates__tender benefit-tender"
                                 >
                                    <div class="benefit-tender__row">
                                       <img
                                          src="@/assets/img/icons/benefit-tools__star.svg"
                                          alt=""
                                       />
                                       <img
                                          src="@/assets/img/icons/benefit-tools__emblem.svg"
                                          alt=""
                                       />
                                    </div>
                                    <div class="benefit-tender__text">
                                       Поставка оборудования телевизионных
                                       систем видеонаблюдения
                                    </div>
                                 </li>
                                 <li
                                    class="benefit-templates__tender benefit-tender"
                                 >
                                    <div class="benefit-tender__row">
                                       <img
                                          src="@/assets/img/icons/benefit-tools__star.svg"
                                          alt=""
                                       />
                                       <img
                                          src="@/assets/img/icons/benefit-tools__emblem.svg"
                                          alt=""
                                       />
                                    </div>
                                    <div class="benefit-tender__text">
                                       Поставка оборудования телевизионных
                                       систем видеонаблюдения
                                    </div>
                                 </li>
                                 <li
                                    class="benefit-templates__tender benefit-tender"
                                 >
                                    <div class="benefit-tender__row">
                                       <img
                                          src="@/assets/img/icons/benefit-tools__star.svg"
                                          alt=""
                                       />
                                       <img
                                          src="@/assets/img/icons/benefit-tools__emblem.svg"
                                          alt=""
                                       />
                                    </div>
                                    <div class="benefit-tender__text">
                                       Поставка оборудования телевизионных
                                       систем видеонаблюдения
                                    </div>
                                 </li>
                              </ul>
                           </div>
                        </div>
                     </div>
                     <div
                        class="benefit-tools__notifications benefit-notifications benefit-tools__tab"
                        id="benefitNotifications"
                        :class="{ active: benefit == 'benefitNotifications' }"
                     >
                        <div class="benefit-notifications__wrapper">
                           <div class="benefit-notifications__title">
                              Уведомления
                           </div>
                           <ul class="benefit-notifications__list">
                              <li class="benefit-notifications__item orange">
                                 <div class="benefit-notifications__row">
                                    <div
                                       class="benefit-notifications__reminder"
                                    >
                                       <svg
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                       >
                                          <path
                                             d="M8.00065 13.3334C9.41514 13.3334 10.7717 12.7715 11.7719 11.7713C12.7721 10.7711 13.334 9.41453 13.334 8.00004C13.334 6.58555 12.7721 5.229 11.7719 4.2288C10.7717 3.22861 9.41514 2.66671 8.00065 2.66671C6.58616 2.66671 5.22961 3.22861 4.22942 4.2288C3.22922 5.229 2.66732 6.58555 2.66732 8.00004C2.66732 9.41453 3.22922 10.7711 4.22942 11.7713C5.22961 12.7715 6.58616 13.3334 8.00065 13.3334ZM8.00065 1.33337C8.87613 1.33337 9.74304 1.50581 10.5519 1.84084C11.3607 2.17588 12.0956 2.66694 12.7147 3.286C13.3338 3.90505 13.8248 4.63998 14.1598 5.44882C14.4949 6.25765 14.6673 7.12456 14.6673 8.00004C14.6673 9.76815 13.9649 11.4638 12.7147 12.7141C11.4645 13.9643 9.76876 14.6667 8.00065 14.6667C4.31398 14.6667 1.33398 11.6667 1.33398 8.00004C1.33398 6.23193 2.03636 4.53624 3.28661 3.286C4.53685 2.03575 6.23254 1.33337 8.00065 1.33337ZM8.33398 4.66671V8.16671L11.334 9.94671L10.834 10.7667L7.33398 8.66671V4.66671H8.33398Z"
                                             fill="#E76014"
                                          />
                                       </svg>
                                       <span
                                          >18 ч. до завершение приема
                                          заявок</span
                                       >
                                    </div>
                                    <div class="benefit-notifications__date">
                                       24.04.2023, 23:54
                                    </div>
                                 </div>
                                 <p class="benefit-notifications__text">
                                    (ПКР-011625-23) Выполнение работ по
                                    разработке проектной документации по
                                    капитальному ремонту общего имущества
                                    многоквартирного(ых) дома(ов) и завершение
                                    работ по…
                                 </p>
                              </li>
                              <li class="benefit-notifications__item yellow">
                                 <div class="benefit-notifications__row">
                                    <div
                                       class="benefit-notifications__reminder"
                                    >
                                       <svg
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                       >
                                          <path
                                             d="M8.00065 13.3334C9.41514 13.3334 10.7717 12.7715 11.7719 11.7713C12.7721 10.7711 13.334 9.41453 13.334 8.00004C13.334 6.58555 12.7721 5.229 11.7719 4.2288C10.7717 3.22861 9.41514 2.66671 8.00065 2.66671C6.58616 2.66671 5.22961 3.22861 4.22942 4.2288C3.22922 5.229 2.66732 6.58555 2.66732 8.00004C2.66732 9.41453 3.22922 10.7711 4.22942 11.7713C5.22961 12.7715 6.58616 13.3334 8.00065 13.3334ZM8.00065 1.33337C8.87613 1.33337 9.74304 1.50581 10.5519 1.84084C11.3607 2.17588 12.0956 2.66694 12.7147 3.286C13.3338 3.90505 13.8248 4.63998 14.1598 5.44882C14.4949 6.25765 14.6673 7.12456 14.6673 8.00004C14.6673 9.76815 13.9649 11.4638 12.7147 12.7141C11.4645 13.9643 9.76876 14.6667 8.00065 14.6667C4.31398 14.6667 1.33398 11.6667 1.33398 8.00004C1.33398 6.23193 2.03636 4.53624 3.28661 3.286C4.53685 2.03575 6.23254 1.33337 8.00065 1.33337ZM8.33398 4.66671V8.16671L11.334 9.94671L10.834 10.7667L7.33398 8.66671V4.66671H8.33398Z"
                                             fill="#E76014"
                                          />
                                       </svg>
                                       <span
                                          >18 ч. до завершение приема
                                          заявок</span
                                       >
                                    </div>
                                    <div class="benefit-notifications__date">
                                       24.04.2023, 23:54
                                    </div>
                                 </div>
                                 <p class="benefit-notifications__text">
                                    Оказание услуг по ремонту видеогастроскопа
                                    Pentax
                                 </p>
                              </li>
                              <li class="benefit-notifications__item green">
                                 <div class="benefit-notifications__row">
                                    <div
                                       class="benefit-notifications__reminder"
                                    >
                                       <svg
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                       >
                                          <path
                                             d="M8.00065 13.3334C9.41514 13.3334 10.7717 12.7715 11.7719 11.7713C12.7721 10.7711 13.334 9.41453 13.334 8.00004C13.334 6.58555 12.7721 5.229 11.7719 4.2288C10.7717 3.22861 9.41514 2.66671 8.00065 2.66671C6.58616 2.66671 5.22961 3.22861 4.22942 4.2288C3.22922 5.229 2.66732 6.58555 2.66732 8.00004C2.66732 9.41453 3.22922 10.7711 4.22942 11.7713C5.22961 12.7715 6.58616 13.3334 8.00065 13.3334ZM8.00065 1.33337C8.87613 1.33337 9.74304 1.50581 10.5519 1.84084C11.3607 2.17588 12.0956 2.66694 12.7147 3.286C13.3338 3.90505 13.8248 4.63998 14.1598 5.44882C14.4949 6.25765 14.6673 7.12456 14.6673 8.00004C14.6673 9.76815 13.9649 11.4638 12.7147 12.7141C11.4645 13.9643 9.76876 14.6667 8.00065 14.6667C4.31398 14.6667 1.33398 11.6667 1.33398 8.00004C1.33398 6.23193 2.03636 4.53624 3.28661 3.286C4.53685 2.03575 6.23254 1.33337 8.00065 1.33337ZM8.33398 4.66671V8.16671L11.334 9.94671L10.834 10.7667L7.33398 8.66671V4.66671H8.33398Z"
                                             fill="#E76014"
                                          />
                                       </svg>
                                       <span
                                          >18 ч. до завершение приема
                                          заявок</span
                                       >
                                    </div>
                                    <div class="benefit-notifications__date">
                                       24.04.2023, 23:54
                                    </div>
                                 </div>
                                 <p class="benefit-notifications__text">
                                    Телематические услуги для Нижневартовского
                                    центра ОВД филиала Аэронавигация Севера
                                    Сибири ФГУП Госкорпорация по ОрВД
                                 </p>
                              </li>
                              <li class="benefit-notifications__item blue">
                                 <div class="benefit-notifications__row">
                                    <div
                                       class="benefit-notifications__reminder"
                                    >
                                       <svg
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                       >
                                          <path
                                             d="M8 14C11.3 14 14 11.3 14 8C14 4.7 11.3 2 8 2C4.7 2 2 4.7 2 8C2 11.3 4.7 14 8 14ZM8.18 5.174C8.3 5.066 8.444 5 8.6 5C8.762 5 8.9 5.066 9.026 5.174C9.14 5.3 9.2 5.444 9.2 5.6C9.2 5.762 9.14 5.9 9.026 6.026C8.9 6.14 8.762 6.2 8.6 6.2C8.444 6.2 8.3 6.14 8.18 6.026C8.066 5.9 8 5.762 8 5.6C8 5.444 8.066 5.3 8.18 5.174ZM6.68 7.982C6.68 7.982 7.982 6.95 8.456 6.908C8.9 6.872 8.81 7.382 8.768 7.646L8.762 7.682C8.678 8 8.576 8.384 8.474 8.75C8.246 9.584 8.024 10.4 8.078 10.55C8.138 10.754 8.51 10.496 8.78 10.316C8.816 10.292 8.846 10.268 8.876 10.25C8.876 10.25 8.924 10.202 8.972 10.268C8.984 10.286 8.996 10.304 9.008 10.316C9.062 10.4 9.092 10.43 9.02 10.478L8.996 10.49C8.864 10.58 8.3 10.976 8.072 11.12C7.826 11.282 6.884 11.822 7.028 10.772C7.154 10.034 7.322 9.398 7.454 8.9C7.7 8 7.808 7.592 7.256 7.946C7.034 8.078 6.902 8.162 6.824 8.216C6.758 8.264 6.752 8.264 6.71 8.186L6.692 8.15L6.662 8.102C6.62 8.042 6.62 8.036 6.68 7.982Z"
                                             fill="#81B6DD"
                                          />
                                       </svg>
                                       <span>Система</span>
                                    </div>
                                    <div class="benefit-notifications__date">
                                       24.04.2023, 23:54
                                    </div>
                                 </div>
                                 <p class="benefit-notifications__text">
                                    (ПКР-011625-23) Выполнение работ по
                                    разработке проектной документации по
                                    капитальному ремонту общего имущества
                                    многоквартирного(ых) дома(ов) и завершение
                                    работ по…
                                 </p>
                              </li>
                           </ul>
                        </div>
                     </div>
                     <div
                        class="benefit-tools__tags benefit-tags benefit-tools__tab"
                        id="benefitTags"
                        :class="{ active: benefit == 'benefitTags' }"
                     >
                        <div class="benefit-tags__row">
                           <div class="benefit-tags__filters">
                              <div class="benefit-tags__title">Метки</div>
                              <div class="benefit-tags__list">
                                 <li class="benefit-tags__item">
                                    <input
                                       id="benefit-tags__analys"
                                       type="checkbox"
                                    />
                                    <label for="benefit-tags__analys">
                                       <div class="box"></div>
                                       <span>Анализировать</span>
                                    </label>
                                 </li>
                                 <li class="benefit-tags__item">
                                    <input
                                       id="benefit-tags__analys1"
                                       type="checkbox"
                                    />
                                    <label for="benefit-tags__analys1">
                                       <div class="box"></div>
                                       <span>Участвовать</span>
                                    </label>
                                 </li>
                                 <li class="benefit-tags__item">
                                    <input
                                       id="benefit-tags__analys2"
                                       type="checkbox"
                                    />
                                    <label for="benefit-tags__analys2">
                                       <div class="box"></div>
                                       <span>Выиграли</span>
                                    </label>
                                 </li>
                                 <li class="benefit-tags__item">
                                    <input
                                       id="benefit-tags__analys3"
                                       type="checkbox"
                                    />
                                    <label for="benefit-tags__analys3">
                                       <div class="box"></div>
                                       <span>Субподряд</span>
                                    </label>
                                 </li>
                              </div>
                           </div>
                           <ul class="benefit-tags__main">
                              <li class="benefit-tag analys">
                                 <div class="benefit-tag__row">
                                    <div class="benefit-tag__filters">
                                       <div class="benefit-tag__filter">
                                          <span>Анализировать</span>
                                       </div>
                                    </div>
                                    <div class="benefit-tag__icons">
                                       <div class="benefit-tag__icon">
                                          ТЭК Торг
                                       </div>
                                       <div class="benefit-tag__icon">
                                          44-ФЗ
                                       </div>
                                    </div>
                                 </div>
                                 <div
                                    class="benefit-tag__title benefit-tags__title"
                                 >
                                    Поставка хлебобулочных изделий
                                 </div>
                                 <div class="benefit-tag__branch">
                                    Офисное оборудование
                                 </div>
                              </li>
                              <li class="benefit-tag part">
                                 <div class="benefit-tag__row">
                                    <div class="benefit-tag__filters">
                                       <div class="benefit-tag__filter">
                                          <span>Участвовать</span>
                                       </div>
                                    </div>
                                    <div class="benefit-tag__icons">
                                       <div class="benefit-tag__icon">Bico</div>
                                       <div class="benefit-tag__icon">
                                          44-ФЗ
                                       </div>
                                    </div>
                                 </div>
                                 <div
                                    class="benefit-tag__title benefit-tags__title"
                                 >
                                    Поставка хлебобулочных изделий
                                 </div>
                                 <div class="benefit-tag__branch">
                                    Офисное оборудование
                                 </div>
                              </li>
                              <li class="benefit-tag contract">
                                 <div class="benefit-tag__row">
                                    <div class="benefit-tag__filters">
                                       <div class="benefit-tag__filter">
                                          <span>Субподряд</span>
                                       </div>
                                    </div>
                                    <div class="benefit-tag__icons">
                                       <div class="benefit-tag__icon">
                                          РОСЭЛТОРГ
                                       </div>
                                       <div class="benefit-tag__icon">
                                          44-ФЗ
                                       </div>
                                    </div>
                                 </div>
                                 <div
                                    class="benefit-tag__title benefit-tags__title"
                                 >
                                    Поставка хлебобулочных изделий
                                 </div>
                                 <div class="benefit-tag__branch">
                                    Офисное оборудование
                                 </div>
                              </li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="escort" data-aos="fade-up" data-aos-duration="1000">
         <div class="container">
            <div class="escort__grid">
               <div class="escort__item escort-main">
                  <div class="escort-main__title title">
                     Тендерное сопровождение — доверьте процесс участия в
                     закупках профессионалам
                  </div>
                  <router-link
                     to="/profile/escort"
                     class="escort-main__button btn"
                     >Узнать больше</router-link
                  >
               </div>
               <div class="escort__item escort-pref risk">
                  <div class="escort-pref__title subtitle">
                     Минимизация рисков
                  </div>
                  <div class="escort-pref__text">
                     Обеспечиваем бизнесу профессиональную поддержку на каждом
                     этапе участия в тендере
                  </div>
               </div>
               <div class="escort__item escort-pref competition">
                  <div class="escort-pref__title subtitle">
                     Конкурентное преимущество
                  </div>
                  <div class="escort-pref__text">
                     Помогаем создать конкурентоспособное предложение, которое
                     максимально соответствует требованиям заказчика
                  </div>
               </div>
               <div class="escort__item escort-pref eco">
                  <div class="escort-pref__title subtitle">
                     Экономия времени и ресурсов
                  </div>
                  <div class="escort-pref__text">
                     Экономим ваше время и ресурсы на подготовку и участие в
                     закупках, чтобы вы могли сосредоточиться на своих основных
                     задачах
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section style="transform: translateY(-80px)" ref="subs"></section>
      <section class="rate" data-aos="fade-up" data-aos-duration="1000">
         <div class="container">
            <div class="rate__card">
               <div class="rate__title title">
                  Выберите подходящий тариф, отвечающий вашим требованиям
               </div>
               <div class="rate__row">
                  <div
                     class="rate__item rate-item"
                     v-for="(item, index) in subs"
                     :key="index"
                  >
                     <div class="rate-item__image">
                        <img
                           v-if="item.get_image"
                           :src="item.get_image"
                           alt=""
                        />
                     </div>
                     <div class="rate-item__period">
                        <span>{{ item.name }}</span>
                        {{ DateSort(item.duration) }}
                     </div>
                     <div class="rate-item__price">
                        {{ numberWithSpaces(item.price) }} ₽
                     </div>
                     <div class="rate-item__conditions">
                        <span
                           >Количество
                           <b v-if="item.request_num == 9999">
                              запросов не ограничено</b
                           >
                           <b v-else> {{ item.request_num }} запросов</b>
                        </span>
                        <span>{{ item.ustr }}</span>
                     </div>
                     <a @click="GoToSub()" class="rate-item__button btn"
                        >Подключить тариф</a
                     >
                     <span class="rate-item__bonus">
                        <router-link to="/escort"
                           >Подключить с сопровождением</router-link
                        >
                        +15 000 ₽</span
                     >
                     <span class="rate-item__bonus rate-item__bonus_adapt"
                        ><router-link to="/escort"
                           >Подключить с <br />
                           сопровождением</router-link
                        >
                        +15 000 ₽</span
                     >
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!--          <section class="promo"
           data-aos="fade-up" 
           data-aos-duration="1000"
         >
            <div class="container">
               <div class="promo__card">
                  <div class="promo__title title">Используйте промокод «ПРОМО2023», чтобы получить бесплатный доступ на
                     1 месяц</div>
                  <a @click="GoToSub()" class="promo__button">Зарегистрироваться</a>
               </div>
            </div>
         </section> -->
      <section style="transform: translateY(-80px)" ref="preim"></section>
      <section class="data" data-aos="fade-up" data-aos-duration="1000">
         <div class="container">
            <div class="data__grid">
               <div class="data__item data__item_small data-actual">
                  <div class="data-actual__title data__title_small">
                     Актуальная информация
                  </div>
                  <div class="data-actual__image">
                     <img src="@/assets/img/icons/actual.svg" alt="" />
                  </div>
               </div>
               <div class="data__item data__item_small data-control">
                  <div class="data-control__title data__title_small">
                     Контроль статуса закупок
                  </div>
                  <div class="data-control__image">
                     <img src="@/assets/img/icons/control.svg" alt="" />
                  </div>
                  <div class="data-control__banners">
                     <div
                        class="data-control__banner data-control__banner_green"
                     >
                        Прием заявок
                     </div>
                     <div
                        class="data-control__banner data-control__banner_grey"
                     >
                        Завершен
                     </div>
                     <div
                        class="data-control__banner data-control__banner_orange"
                     >
                        Отменен
                     </div>
                  </div>
               </div>
               <div class="data__item data-behaviour">
                  <div class="data-behaviour__title data__title_small">
                     Отслеживание поведения контрагента
                  </div>
                  <div class="data-behaviour__content">
                     <div class="data-behaviour__item">
                        <div class="data-behaviour__text">
                           <div class="data-behaviour__subtitle">
                              Начальная цена аукциона
                           </div>
                           <div class="data-behaviour__price">
                              <span>50 000 000 ₽</span>
                           </div>
                        </div>
                        <div class="data-behaviour__progressbar">
                           <div
                              class="data-behaviour__progressbar_active"
                           ></div>
                        </div>
                     </div>
                     <div class="data-behaviour__item">
                        <div class="data-behaviour__text">
                           <div class="data-behaviour__subtitle">
                              Цена договора
                           </div>
                           <div class="data-behaviour__price">
                              <span>40 000 000 ₽</span>
                              <div class="label">-20%</div>
                           </div>
                        </div>
                        <div class="data-behaviour__progressbar">
                           <div
                              class="data-behaviour__progressbar_active"
                           ></div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="data__item data__item_big data-structured">
                  <div class="data-structured__wrapper">
                     <div class="data-structured__title data__title_big">
                        Структурированные и понятные данные
                     </div>
                     <div class="data-structured__text">
                        Принимайте более обоснованные и эффективные
                        бизнес-решения на основе детальной информации по
                        закупкам и анализа конкурентов
                     </div>
                  </div>
                  <div class="data-structured__stats data-stats">
                     <div class="data-stats__title">Топ по контрактам</div>
                     <ul class="data-stats__list">
                        <li>
                           <a
                              @click="BenefitTags('data_providers')"
                              class="data-stats__link"
                              :class="{
                                 active: tagBenefit == 'data_providers',
                              }"
                           >
                              Топ-5 поставщиков
                           </a>
                        </li>
                        <li>
                           <a
                              @click="BenefitTags('data_customers')"
                              class="data-stats__link"
                              :class="{
                                 active: tagBenefit == 'data_customers',
                              }"
                           >
                              Топ-5 заказчиков
                           </a>
                        </li>
                     </ul>
                     <div
                        class="data-structured__tab data-providers"
                        id="data-providers"
                        :class="{ active: tagBenefit == 'data_providers' }"
                     >
                        <div class="data-providers__row">
                           <div class="data-providers__image">
                              <!-- <picture><source srcset="@/assets/img/main/data-stats.webp" type="image/webp"><img src="@/assets/img/main/data-stats.png" alt=""></picture> -->
                              <img
                                 src="@/assets/img/main/data-stats.svg"
                                 alt=""
                              />
                           </div>
                           <ul class="data-providers__list">
                              <li
                                 class="data-providers__item data-providers__item_gold"
                              >
                                 <span class="data-providers__name"
                                    >ГБУ «ГУБ Кировского района»</span
                                 >
                                 <span class="data-providers__price"
                                    >192 493 780 ₽</span
                                 >
                                 <span class="data-providers__count"
                                    >161 договор</span
                                 >
                              </li>
                              <li
                                 class="data-providers__item data-providers__item_white"
                              >
                                 <span class="data-providers__name"
                                    >ПАО «ЮгСтрой»</span
                                 >
                                 <span class="data-providers__price"
                                    >192 493 780 ₽</span
                                 >
                                 <span class="data-providers__count"
                                    >161 договор</span
                                 >
                              </li>
                              <li
                                 class="data-providers__item data-providers__item_grey"
                              >
                                 <span class="data-providers__name"
                                    >ГУП «Гаттехмедфарм»</span
                                 >
                                 <span class="data-providers__price"
                                    >192 493 780 ₽</span
                                 >
                                 <span class="data-providers__count"
                                    >161 договор</span
                                 >
                              </li>
                           </ul>
                        </div>
                     </div>
                     <div
                        class="data-structured__tab data-customers"
                        id="data-customers"
                        :class="{ active: tagBenefit == 'data_customers' }"
                     >
                        <div class="data-customers__row">
                           <div class="data-customers__image">
                              <picture
                                 ><source
                                    srcset="@/assets/img/main/data-stats.webp"
                                    type="image/webp" />
                                 <img
                                    src="@/assets/img/main/data-stats.png"
                                    alt=""
                              /></picture>
                           </div>
                           <ul class="data-customers__list">
                              <li
                                 class="data-customers__item data-customers__item_gold"
                              >
                                 <span class="data-customers__name"
                                    >ГБУ «ГУБ Кировского района»</span
                                 >
                                 <span class="data-customers__price"
                                    >192 493 780 ₽</span
                                 >
                                 <span class="data-customers__count"
                                    >161 договор</span
                                 >
                              </li>
                              <li
                                 class="data-customers__item data-customers__item_grey"
                              >
                                 <span class="data-customers__name"
                                    >ГУП «Гаттехмедфарм»</span
                                 >
                                 <span class="data-customers__price"
                                    >192 493 780 ₽</span
                                 >
                                 <span class="data-customers__count"
                                    >161 договор</span
                                 >
                              </li>
                              <li
                                 class="data-customers__item data-customers__item_white"
                              >
                                 <span class="data-customers__name"
                                    >ПАО «ЮгСтрой»</span
                                 >
                                 <span class="data-customers__price"
                                    >192 493 780 ₽</span
                                 >
                                 <span class="data-customers__count"
                                    >161 договор</span
                                 >
                              </li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="data__item data__item_medium data-amount">
                  <div class="data-amount__title data__title_small">
                     Большой объем данных
                  </div>
                  <div class="data-amount__grid">
                     <div class="data-amount__item">
                        <div class="data-amount__image">
                           <img src="@/assets/img/icons/money.svg" alt="" />
                        </div>
                        <div class="data-amount__span">ком</div>
                     </div>
                     <div class="data-amount__item">
                        <div class="data-amount__image">
                           <img src="@/assets/img/icons/emblem.svg" alt="" />
                        </div>
                        <div class="data-amount__span">223</div>
                     </div>
                     <div class="data-amount__item">
                        <div class="data-amount__image">
                           <img src="@/assets/img/icons/emblem.svg" alt="" />
                        </div>
                        <div class="data-amount__span">44</div>
                     </div>
                     <div class="data-amount__item">
                        <div class="data-amount__image">
                           <img src="@/assets/img/icons/helmet.svg" alt="" />
                        </div>
                        <div class="data-amount__span">615</div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section style="transform: translateY(-80px)" ref="contactform"></section>
      <section class="early">
         <div class="container">
            <div class="early__card">
               <div class="early__title">
                  Отправьте заявку на<br />тестовый доступ
               </div>
               <form class="early__row">
                  <!-- <div class="early__inputs"> -->
                  <div class="early__phone">
                     <span>Имя</span>
                     <input type="text" v-model="name" />
                  </div>
                  <div class="early__phone">
                     <span>Email</span>
                     <input type="text" v-model="email" />
                  </div>
                  <div class="early__phone">
                     <span>Телефон</span>
                     <input
                        v-model="phone"
                        type="tel"
                        v-mask="'+# (###) ###-##-##'"
                     />
                  </div>
                  <!-- </div> -->
                  <!-- <div class="early__other"> -->
                  <button
                     class="btn early__button"
                     type="button"
                     :disabled="!email"
                     @click="sendEmail()"
                  >
                     Отправить заявку
                  </button>
                  <div class="early__text">
                     Нажимая кнопку «Отправить заявку», вы даете согласие на
                     обработку персональных данных
                     <!-- </div> -->
                  </div>
               </form>
            </div>
         </div>
      </section>
   </main>

   <ConfirmPassword
      :modal="confirm_password"
      :uid="uid"
      :token="token"
      @conf="confirm_password = $event"
   />
</template>

<style type="scss">
.mainPage .early__button:disabled {
   opacity: 0.8;
}

b {
   font-weight: 200;
   margin: 0 4px 0;
}
</style>

<script>
import axios from "axios";
import { mask } from "vue-the-mask";
import { toast } from "bulma-toast";
import moment from "moment";

import Header from "@/components/Header/Header";
import ProfileHeader from "@/components/Header/ProfileHeader";

import ConfirmPassword from "@/components/Modal/ConfirmPassword.vue";

import AOS from "aos";
import { onMounted } from "@vue/runtime-core";
import { ref } from "vue";
import { mapGetters } from "vuex";
export default {
   name: "HomeView",
   directives: { mask },
   data() {
      return {
         name: "",
         phone: "+7",
         email: "",
         benefit: "benefitTemplates",
         tagBenefit: "data_providers",
         subs: [],

         uid: "",
         token: "",
         confirm_password: false,

         open_tarif: false,
      };
   },
   setup() {
      onMounted(() => {
         AOS.init();
      });
   },
   computed: {
      ...mapGetters(["AUTH"]),
   },
   mounted() {
      if (this.$route.query.redirect) {
         setTimeout(() => {
            this.$refs[this.$route.query.redirect].scrollIntoView({
               behavior: "smooth",
               block: "start",
            });
         }, 100);
      }
   },
   methods: {
      GoToRef(val) {
         this.$refs[val].scrollIntoView({ behavior: "smooth" });
      },
      showBlock(index) {
         this.showJobBlock = index;
      },
      goTo(redirect) {
         this.$router.push(redirect);
      },
      scrollToContact() {
         this.$refs.contact.scrollIntoView({ behavior: "smooth" });
      },
      scrollToParthners() {
         this.$refs.parthners.scrollIntoView({ behavior: "smooth" });
      },
      ModalOpenLogout() {
         this.showmodallogout = !this.showmodallogout;
      },
      BenefitTabs(value) {
         this.benefit = value;
      },
      BenefitTags(value) {
         this.tagBenefit = value;
      },
      async sendEmail() {
         const data = {
            name: this.name,
            email: this.email,
            phone: this.phone,
         };

         await axios
            .post("/api/v1/send_email/", data)
            .then((response) => {
               toast({
                  message: "Успешно отправлено",
                  type: "is-success",
                  dismissible: true,
                  pauseOnHover: true,
                  duration: 3000,
                  position: "bottom-right",
               });
               this.name = "";
               this.email = "";
               this.phone = "";
            })
            .catch((error) => {
               console.log(error);
            });
      },
      async GetSubs() {
         await axios
            .get("/api/v1/subs/")
            .then((response) => {
               this.subs = response.data;
               console.log(response.data);
            })
            .catch((error) => {
               console.log(error);
            });
      },
      numberWithSpaces(x) {
         return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },
      DateSort(value) {
         const item = Math.round(moment.duration(value, "days").asMonths());
         var result = item + " " + "месяцев";
         if (item / 12 == 1) result = "1 год";
         if (item == 3) result = item + " " + "месяца";
         if (item == 1) result = item + " " + "месяц";
         return result;
      },
      GoToSub() {
         this.open_tarif = false;

         setTimeout(() => {
            this.open_tarif = true;
         }, 100);
      },
   },
   components: {
      "header-main": Header,
      ConfirmPassword,
      ProfileHeader,
   },
   watch: {
      "$route.query.redirect"(value) {
         setTimeout(() => {
            console.log(value);
            this.$refs[value].scrollIntoView({
               behavior: "smooth",
               block: "start",
            });
         }, 100);
      },
   },
   created() {
      moment.locale("ru");
      this.GetSubs();

      if (this.$route.query.restore_user) {
         setTimeout(() => {
            this.confirm_password = true;
            this.uid = this.$route.query.restore_user;
            this.token = this.$route.query.md;
         }, 100);
      }
   },
};
</script>
