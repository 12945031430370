<template>
   <div class="select-item reg">
      <div class="select-item__wrapper">
         <input
            type="text"
            :placeholder="placeholder"
            class="search__input"
            v-model="inputValue"
            @focus="isOpen = true"
         />
         <ul class="select-item__list" v-if="isOpen && filteredOptions.length">
            <li
               v-for="list in filteredOptions"
               :value="list?.ID"
               :key="list"
               @click="selectOption(list)"
            >
               {{ list?.RegionName }}
            </li>
         </ul>
      </div>
   </div>
</template>
<script setup>
import { computed, ref, watch, onMounted } from "vue";
const props = defineProps({
   options: Array,
   placeholder: String,
   modelValue: String,
});
const emit = defineEmits(["update:modelValue"]);

let id = ref(0);
let isOpen = ref(false);
const inputValue = ref("");
const filteredOptions = computed(() => {
   let res = props?.options.filter((item) => {
      if (item?.RegionName) {
         return item?.RegionName.toLowerCase().includes(
            inputValue.value.toLowerCase()
         );
      }
   });
   res.splice(5, res.length - 5);
   return res;
});

watch(inputValue, (value) => {
   filteredOptions.value.forEach((item) => {
      if (item?.RegionName.toLowerCase() == value.toLowerCase()) {
         id.value = item?.ID;
         isOpen.value = false;
      } else {
         isOpen.value = true;
         id.value = 0;
      }
   });
});

function selectOption(item) {
   isOpen.value = false;
   inputValue.value = item?.RegionName;
   id.value = item?.ID;
}
watch(id, (value) => {
   emit("update:modelValue", value);
});
onMounted(() => {
   document.addEventListener("click", (e) => {
      if (!e.target.closest(".select-item.reg")) {
         isOpen.value = false;
      }
   });
});
</script>
